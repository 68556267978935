.calendar {
  z-index: 106;
  border-radius: 6px;
  box-shadow: 0px 5px 40px 5px rgba(0, 0, 0, 0.1);
}

.calendar-select {
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
}
.head {
  font-size: 14px;
  .head {
    font-weight: 600;
  }
}

.nav_icon * {
  fill: #8b5cf6 !important;
}

.selected {
  border-color: #f0f0fa;
  color: #fff;
  background: #e5e7eb;
  font-family: SF-Pro;

  &:not([disabled]) {
    background: #8b5cf6;
    border: none;
    color: #fff;
    font-family: SF-Pro;
    font-weight: 400;
  }

  &:hover {
    &:not([disabled]) {
      color: #fff;
    }
  }
}

.today {
  font-weight: 400;
  background: #8b5cf6;
  color: #1f2937;
  border-radius: 6px;
}

.today_dark {
  font-weight: 400;
  background: #6d28d9;
  color: #fff;
  border-radius: 6px;
}

.day_range_start {
  border-radius: 0 !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  background-color: #8b5cf6 !important;
  color: #fff !important;

  &_dark {
    border-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    background-color: #6d28d9 !important;
    color: #fff !important;
  }
}

.day_range_end {
  border-radius: 0 !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  background-color: #8b5cf6 !important;
  color: #fff !important;

  &_dark {
    border-radius: 0 !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    background-color: #6d28d9 !important;
    color: #fff !important;
  }
}

.day_range_end_only {
  border-radius: 6px !important;
  background-color: #8b5cf6 !important;
  color: #fff !important;

  &_dark {
    border-radius: 6px !important;
    background-color: #6d28d9 !important;
    color: #fff !important;
  }
}

.day_range_middle {
  background-color: #e5e7eb !important;
  color: #1f2937 !important;
  border-radius: 0 !important;

  &_dark {
    background-color: #1f2937 !important;
    color: #e1e1e1 !important;
    border-radius: 0 !important;
  }
}

.input-wrapper {
  cursor: pointer;
  border-radius: 10px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97px;
}

.input {
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    font-family: SF-Pro;
    font-size: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 14px;
  height: 14px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 18px 13px;
  width: 100%;
  &__days {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SF-Pro;
    font-weight: 500;
    margin: 0;
    & span {
      font-weight: 700;
      margin: 0px 5px 0px 0px;
    }
    & div {
      max-width: 350px;
    }
  }
}
.month:first-child,
.month_dark:first-child {
  padding-right: 12px;
}
.month:last-child,
.month_dark:last-child {
  padding-left: 12px;
}
.month:first-child {
  border-right: 1px solid #e5e7eb;
}
.month_dark:first-child {
  border-right: 1px solid #1f2937;
}

.cancel_btn {
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  border-radius: 25px;
  font-family: SF-Pro;
  font-weight: 600;
  font-size: 16px;
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px 0px 12px 0px;

  &__label {
    font-family: SF-Pro;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #3b454e;

    &_dark {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: #e1e1e1;
    }
  }
}
