.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center vertically */
  align-items: center;
  /* Center horizontally */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  /* Semi-transparent background */

  .modalContent {
    background-color: white;
    max-width: 600px;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
