.mobile-dropdown-blur {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: z-index 0.5s ease 0s;
  transition: opacity 1s ease 0s;

  &--open {
    z-index: 49;
    opacity: 1;
    transition: z-index 0.5s ease 0s;
    transition: opacity 1s ease 0s;
  }
}

.head {
  margin: 8px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.head_row {
  display: none;
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px 0px 12px 0px;
  &_label {
    color: #3b454e;
    font-family: SF-Pro;
    font-size: 16px;
    font-weight: 500;
  }
  &_label_dark {
    color: #e1e1e1;
    font-family: SF-Pro;
    font-size: 16px;
    font-weight: 500;
  }
}

.nav_button_previous,
.nav_button_next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.nav_button_previous {
  left: 0;
}
.nav_button_next {
  right: 0;
}

.months {
  width: 100%;
}

.table {
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.selected {
  border-color: #f0f0fa;
  color: #fff;
  background: #e5e7eb;
  font-family: SF-Pro;

  &:not([disabled]) {
    background: #8b5cf6;
    border: none;
    color: #fff;
    font-family: SF-Pro;
    font-weight: 400;
  }

  &:hover {
    &:not([disabled]) {
      color: #fff;
    }
  }
}

.selected_dark {
  border-color: #f0f0fa;
  color: #fff;
  background: #e5e7eb;
  font-family: SF-Pro;

  &:not([disabled]) {
    background: #6d28d9;
    border: none;
    color: #fff;
    font-family: SF-Pro;
    font-weight: 400;
  }

  &:hover {
    &:not([disabled]) {
      color: #fff;
    }
  }
}

.today {
  font-weight: 400;
  background: #8b5cf6;
  color: #1f2937;
  border-radius: 6px;
}

.today_dark {
  font-weight: 400;
  background: #6d28d9;
  color: #fff;
  border-radius: 6px;
}

.day {
  border: none;
  width: 100%;
  height: 37px;
  border-radius: 6px;
}

.day_range_start {
  border-radius: 0 !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  background-color: #8b5cf6 !important;
  color: #fff !important;
  &_dark {
    border-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    background-color: #6d28d9 !important;
    color: #fff !important;
  }
}
.day_range_end {
  border-radius: 0 !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  background-color: #8b5cf6 !important;
  color: #fff !important;
  &_dark {
    border-radius: 0 !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    background-color: #6d28d9 !important;
    color: #fff !important;
  }
}
.day_range_end_only {
  border-radius: 6px !important;
  background-color: #8b5cf6 !important;
  color: #fff !important;

  &_dark {
    border-radius: 6px !important;
    background-color: #6d28d9 !important;
    color: #fff !important;
  }
}
.day_range_middle {
  background-color: #e5e7eb !important;
  color: #1f2937 !important;
  border-radius: 0;

  &_dark {
    background-color: #1f2937 !important;
    color: #e1e1e1 !important;
    border-radius: 0;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 18px 13px;
  width: 100%;

  &__days {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SF-Pro;
    font-weight: 500;
    margin: 0;

    & span {
      font-weight: 700;
      margin: 0px 5px 0px 0px;
    }

    & div {
      max-width: 350px;
    }
  }
}

.cancel_btn {
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 32px;
  border-radius: 25px;
  font-family: SF-Pro;
  font-weight: 600;
  font-size: 16px;
}

.option_selected {
  position: relative;
  &::after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background: url("../../../../assets/icons/collection/check.svg") 0 0 /
      contain no-repeat;
  }
}
