.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.gray-icon * {
  stroke: #9ca3af;
  stroke-width: 2px;
}

.height-14 * {
  height: 14px;
}
.height-12 * {
  height: 12px;
}
.height-24 * {
  height: 24px;
}
