.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  font-family: SF-Pro;

  & input,
  & textarea {
    font-family: SF-Pro;
    font-size: 18px;
    transition: border-color 0.2s ease 0s;
    @media (max-width: 992px) {
      font-size: 16px;
    }
    &::placeholder {
      font-family: SF-Pro;
      font-size: 18px;
    }
    &:focus {
      transition: border-color 0.2s ease 0s;
    }
  }

  & textarea {
    resize: none;
  }
}

@media (max-width: 992px) {
  .form {
    & input,
    & textarea {
      font-size: 16px;
      &::placeholder {
        font-size: 16px;
      }
    }
  }
}

.input-with-addon {
  width: 100%;
  position: relative;

  & .input-addon {
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-45%);
    //color: #9CA3AF;
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .input-with-addon {
    & .input-addon {
      font-size: 14px;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}
