.mobile-dropdown-blur {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: z-index 0.5s ease 0s;
  transition: opacity 1s ease 0s;

  &--open {
    z-index: 49;
    opacity: 1;
    transition: z-index 0.5s ease 0s;
    transition: opacity 1s ease 0s;
  }
}
