.header-menu {
  min-width: 65%;
  gap: 70px;

  & .mobile-logo {
    display: none;
  }
}

@media (max-width: 1335px) {
  .header-menu {
    gap: 5px;
  }
}

@media (max-width: 1200px) {
  .header-menu {
    gap: 5px;

    &__item {
      & a {
        font-size: 16px;
      }
    }

    .connect-btn {
      padding: 7px 15px;
    }
  }
}

@media (max-width: 1040px) {
  .header-menu {
    &__item {
      & a {
        font-size: 14px;
      }
    }
  }
}

.burger-button {
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  z-index: 2;
  transform: translateY(18px);

  & span {
    position: absolute;
    left: 3px;
    width: 34px;
    height: 3px;
    transition: all 0.5s ease 0s;

    &:nth-child(1) {
      transform: translateY(-10px);
    }

    &:nth-child(3) {
      transform: translateY(10px);
    }
  }
}

.header--open .burger-button {
  & span {
    opacity: 0;
  }
}
.header-blur {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  @media (max-width: 992px) {
    & {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.2s ease 0s;
    }
  }
}

@media (max-width: 992px) {
  .burger-button {
    display: block;
  }

  .header-menu {
    padding: 32px 16px;
    max-width: 277px;
    background-color: #fff;
    flex-direction: column;
    gap: 24px;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 99 !important;
    transition: left 0.5s ease 0s;

    & .mobile-logo {
      display: block;
      margin: 0px 0px 24px 0px;
    }

    &__list {
      flex-direction: column;
      align-items: start;
      width: 100%;
      gap: 0;
    }

    &__item {
      max-height: 60px;
      width: 100%;
      border: none;
      border-radius: 8px;

      &:active,
      &:hover {
        background-color: #f5f3ff;
        text-decoration: none;
      }
    }

    & .side-buttons {
      width: 100%;
      flex-direction: column-reverse;
      gap: 12px;

      & .connect-btn {
        width: 100%;
      }

      & .func-buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .header--open .header-menu {
    left: 0;
    transition: left 0.5s ease 0s;
  }
  .header--open .header-blur {
    z-index: 95;
    opacity: 1;
    transition: all 0.2s ease 0s;
  }
}
