@font-face {
  font-family: "SF-Pro";
  src: url("./SF-Pro-Display-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro";
  src: url("./SF-Pro-Display-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro";
  src: url("./SF-Pro-Display-Semibold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro";
  src: url("./SF-Pro-Display-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
