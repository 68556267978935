.checkbox {
  position: relative;

  &__input {
    width: 16px;
    height: 16px;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    top: 48%;
    left: 0;
    transform: translateY(-50%);
    z-index: 6;
    cursor: pointer;
  }

  &__label {
    display: inline-block;
    padding: 0px 0px 0px 20px;
    color: #6b7280;
    font-family: SF-Pro;
    font-size: 16px;

    &::before {
      content: "";
      cursor: pointer;
      display: block;
      width: 14px;
      height: 14px;
      padding: 1px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      background-color: #fff;
      position: absolute;
      top: 48%;
      left: 0;
      z-index: 2;
      transform: translateY(-50%);
      transition: all 0.15s ease 0s;
    }

    &::after {
      content: "";
      cursor: pointer;
      display: block;
      width: 10px;
      height: 10px;
      background: url("../../assets/scss/backgrounds/check.png") no-repeat;
      background-size: 10px 10px;
      position: absolute;
      top: 48%;
      left: 2px;
      z-index: 3;
      transform: translateY(-50%);
      opacity: 0;
      transition: all 0.15s ease 0s;
    }
  }

  &__input:checked + &__label:before {
    background-color: #6d28d9;
    border-color: #6d28d9;
    transition: all 0.15s ease 0s;
  }

  &__input:checked + &__label:after {
    opacity: 1;
    transition: all 0.15s ease 0s;
  }
}
