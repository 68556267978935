.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #ddd6fe;
  border-radius: 6px;
  color: #1f2937;
}

html.dark .rdp-button {
  &:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: #1f2937;
    border-radius: 6px;
    color: #e1e1e1;
  }
}

@media (max-width: 992px) {
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: #8b5cf6;
    border-radius: 6px;
    color: #fff;
  }

  html.dark .rdp-button {
    &:hover:not([disabled]):not(.rdp-day_selected) {
      background-color: #6d28d9;
      border-radius: 6px;
      color: #ffffff;
    }
  }
}

.rdp-head {
  display: none;
}

.rdp-caption {
  padding: 0px 0px 8px 0px;
}

.rdp-table {
  margin: 12px 0px 0px 0px;
}
